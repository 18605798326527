// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_home__qHDgV {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width: 1200px) {
  .home_home__qHDgV {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .home_home__qHDgV {
    width: 100%;
    padding: 0 10px;
  }
}
@media (max-width: 480px) {
  .home_home__qHDgV {
    padding: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEI;EAPJ;IAQQ,UAAA;EACN;AACF;AACI;EAXJ;IAYQ,WAAA;IACA,eAAA;EAEN;AACF;AAAI;EAhBJ;IAiBQ,UAAA;EAGN;AACF","sourcesContent":[".home {\n    width: 90%; // Use percentage for general responsiveness\n    max-width: 1400px; // Set a maximum width\n    margin: 0 auto;\n    padding: 0 20px; // Add some padding for smaller screens\n\n    // Responsive styles\n    @media (max-width: 1200px) {\n        width: 95%; // Wider for medium screens\n    }\n\n    @media (max-width: 768px) {\n        width: 100%; // Full width for smaller devices\n        padding: 0 10px; // Less padding for mobile\n    }\n\n    @media (max-width: 480px) {\n        padding: 0; // Further reduce padding for very small screens\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `home_home__qHDgV`
};
export default ___CSS_LOADER_EXPORT___;
