export const host = process.env.REACT_APP_API_URL ?? 'http://75.119.131.15:8080'
export const about = "get/about/"
export const banner = "get/banner/"
export const team = "get/team/"
export const country = "get/country/"
export const postInfo = "postcomment/"
export const details = "orglistbycountry/"
export const getorg = "getorg/"
export const getreqs = "getreqs/"
export const sociallist = "sociallist/"
